import { Controller } from "@hotwired/stimulus"
import { addClass, removeClass } from "@oddcamp/js-utils/src/attribute"

import Stimulus from "../../shared/utils/stimulus"

class NewPaymentPage extends Controller {
  static targets = [`inputRec`, `recTrueShow`, `submitButton`]
  connect() {
    this.setRec()
  }

  setRec = () => {
    const checkedEl = this.inputRecTargets.find((i) => i.checked)

    if (checkedEl?.value == `false`) {
      addClass(this.recTrueShowTargets, `hidden`)
      removeClass(this.submitButtonTarget, `button--disabled`)
      this.submitButtonTarget.disabled = false
    } else if (checkedEl?.value == `true`) {
      removeClass(this.recTrueShowTargets, `hidden`)
      removeClass(this.submitButtonTarget, `button--disabled`)
      this.submitButtonTarget.disabled = false
    }
  }
}

Stimulus.register(`new-payment-page`, NewPaymentPage)
