import { Controller } from "@hotwired/stimulus"
import { hasClosest } from "@oddcamp/js-utils/src/selector"

import Stimulus from "../../shared/utils/stimulus"

class Withdraw extends Controller {
  static outlets = [`confirm-modal`]
  static targets = [`amount`, `button`]

  get buttonController() {
    return this.application.getControllerForElementAndIdentifier(
      this.buttonTarget,
      `button`
    )
  }

  confirm(evt) {
    if (parseFloat(this.amountTarget.value) >= 100000) {
      evt.preventDefault()
      this.confirmModalOutlet.element.__MODAL__.show()
    }
  }

  submit() {
    this.buttonController.toggleLoadingDisabled(true)
    this.element.submit()
  }
}

Stimulus.register(`withdraw`, Withdraw)
