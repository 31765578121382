import { Controller } from "@hotwired/stimulus"
import {
  Chart,
  ArcElement,
  DoughnutController,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js"

import Stimulus from "../../shared/utils/stimulus"

class AccountPieChart extends Controller {
  static values = { data: Array, currency: String, animation: Boolean }
  static targets = [`chart`]
  #chart = null

  connect() {
    this.element.addEventListener(
      `accountPieChart:setChartLabel`,
      this.setChartLabel
    )

    if (Chart.getChart(this.chartTarget)) {
      Chart.getChart(this.chartTarget).destroy()
    }

    Chart.register(
      ArcElement,
      DoughnutController,
      CategoryScale,
      LinearScale,
      PointElement,
      Tooltip
    )

    this.#chart = new Chart(this.chartTarget, {
      type: `doughnut`,
      data: {
        labels: this.dataValue.map((v) => v[`label`]),
        datasets: [
          {
            data: this.dataValue.map((v) => v[`value`]),
            backgroundColor: this.dataValue.map((v) => v[`color`]),
          },
        ],
      },
      options: {
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        animation: this.animationValue ? undefined : false,
        plugins: {
          tooltip: {
            displayColors: false,
            callbacks: {
              label: (context) => {
                const { label, raw } = context

                const amount = new Intl.NumberFormat(
                  document.documentElement.lang,
                  {
                    style: `currency`,
                    currency: this.currencyValue,
                    currencyDisplay: `code`,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }
                ).format(raw)

                return `${label}: ${amount}`
              },
            },
          },
        },
      },
    })
  }

  setChartLabel = (e) => {
    const index = this.dataValue.findIndex((v) => v.id == e.detail.id)
    this.#chart.data.labels[index] = e.detail.label
  }
}

Stimulus.register(`account-pie-chart`, AccountPieChart)
