import { Controller } from "@hotwired/stimulus"
import Stimulus from "../../shared/utils/stimulus"
import { toggleClass } from "@oddcamp/js-utils/src/attribute"

class NewFixedTermAccount extends Controller {
  static targets = [
    `occupation`,
    `occupationSpecification`,
    `requiredField`,
    `employmentField`,
    `fundsOwnerInput`,
    `fundsOwnerInputField`,
    `fundsOwnerDependant`,
    `submitButton`,
  ]

  static values = { fundsOwnerError: String }

  connect() {
    window.setTimeout(this.fundsOwnerChange, 50)
  }

  fundsOwnerChange = () => {
    const checkedEl = this.fundsOwnerInputTargets.find((i) => i.checked)
    const isNotOwner = checkedEl && checkedEl.value == `false` ? true : false

    toggleClass(this.fundsOwnerDependantTargets, `hidden`, isNotOwner)
    this.submitButtonTarget.disabled = isNotOwner

    this.fundsOwnerInputFieldTarget.dispatchEvent(
      new CustomEvent(`setError`, {
        detail: { message: isNotOwner ? this.fundsOwnerErrorValue : `` },
      })
    )
  }
}

Stimulus.register(`new-fixed-term-account`, NewFixedTermAccount)
