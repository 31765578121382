import { Controller } from "@hotwired/stimulus"
import { triggerEvent } from "@oddcamp/js-utils/src/event"

import Stimulus from "../utils/stimulus"

const nowSecs = () => Math.round(+new Date() / 1000)

// countdown

class SessionModalCountdown extends Controller {
  static values = {
    renewUrl: String,
    expires: Number,
    nextTry: Number,
    tolerance: Number,
    autoRenew: Boolean,
  }
  static targets = [`timer`]
  #countdownStarted = false
  #interval = null
  #userActivity = nowSecs()

  connect() {
    this.start()
    this.initTrackUserActivity()
  }

  disconnect = () => {
    this.stop()
    this.deinitTrackUserActivity()
  }

  start = () => {
    this.stop()
    this.#countdownStarted = false
    this.#interval = window.setInterval(this.update, 1000)
  }

  stop = () => {
    window.clearInterval(this.#interval)
  }

  update = () => {
    const now = nowSecs()
    const expiresIn = window.parseInt(this.expiresValue) - now
    const nextTryIn = window.parseInt(this.nextTryValue) - now
    // console.log(`update`, expiresIn, nextTryIn, now - this.#userActivity)

    if (expiresIn < 0) this.renew()
    else if (nextTryIn < 0) {
      if (!this.#countdownStarted) {
        const isUserActive = now - this.#userActivity < this.toleranceValue
        // console.log(`isUserActive`, isUserActive)

        if (isUserActive || this.autoRenewValue) this.renew()
        else {
          this.#countdownStarted = true
          this.element.__MODAL__.show()
        }
      }

      if (this.#countdownStarted) {
        this.timerTarget.innerHTML = new Date(expiresIn * 1000)
          .toISOString()
          .slice(14, 19)
      }
    }
  }

  renew = () => {
    this.stop()
    this.element.__MODAL__.hide()

    navigator.locks.request(
      `session-modal-countdown`,
      async () => await this._renew()
    )
  }

  _renew = () => {
    return new Promise((resolve) => {
      // console.log(`renew`)
      window.Rails.ajax({
        url: this.renewUrlValue,
        type: `post`,
        success: (response) => {
          // console.log(`renew`, response)
          if ([`expired`, `not_signed_in`].includes(response.status))
            triggerEvent(document, `sessionModalExpired:show`)
          else {
            this.expiresValue = response.expires
            this.nextTryValue = response.next_try
            this.start()
          }
          window.setTimeout(resolve, 5000)
        },
      })
    })
  }

  initTrackUserActivity = () => {
    window.addEventListener(`scroll`, this.trackUserActivity, { passive: true })
    window.addEventListener(`keyup`, this.trackUserActivity, { passive: true })
    window.addEventListener(`click`, this.trackUserActivity, { passive: true })
    window.addEventListener(`mousemove`, this.trackUserActivity, { passive: true }) // eslint-disable-line prettier/prettier
    window.addEventListener(`touchstart`, this.trackUserActivity, { passive: true }) // eslint-disable-line prettier/prettier
  }

  deinitTrackUserActivity = () => {
    window.removeEventListener(`scroll`, this.trackUserActivity, { passive: true }) // eslint-disable-line prettier/prettier
    window.removeEventListener(`keyup`, this.trackUserActivity, { passive: true }) // eslint-disable-line prettier/prettier
    window.removeEventListener(`click`, this.trackUserActivity, { passive: true }) // eslint-disable-line prettier/prettier
    window.removeEventListener(`mousemove`, this.trackUserActivity, { passive: true }) // eslint-disable-line prettier/prettier
    window.removeEventListener(`touchstart`, this.trackUserActivity, { passive: true }) // eslint-disable-line prettier/prettier
  }

  trackUserActivity = () => {
    // console.log(`trackUserActivity`)
    this.#userActivity = nowSecs()
  }
}

Stimulus.register(`session-modal-countdown`, SessionModalCountdown)

// expired

class SessionModalExpired extends Controller {
  show = () => {
    this.element.__MODAL__.show()
  }
}

Stimulus.register(`session-modal-expired`, SessionModalExpired)
