import { Controller } from "@hotwired/stimulus"
import { hasClosest } from "@oddcamp/js-utils/src/selector"

import Stimulus from "../../shared/utils/stimulus"

class AccountDropdown extends Controller {
  static values = { open: Boolean }

  connect() {
    document.addEventListener(`click`, this.docClick, { passive: true })
  }

  disconnect() {
    document.removeEventListener(`click`, this.docClick, { passive: true })
  }

  toggle = () => {
    this.openValue = !this.openValue
  }

  docClick = (e) => {
    if (this.openValue && !hasClosest(e.target, this.element)) this.toggle()
  }
}

Stimulus.register(`account-dropdown`, AccountDropdown)
