import { Controller } from "@hotwired/stimulus"
import Stimulus from "../../shared/utils/stimulus"

class WithdrawAccountDetails extends Controller {
  get buttonController() {
    return this.application.getControllerForElementAndIdentifier(
      this.buttonTarget,
      `button`
    )
  }

  copy(evt) {
    navigator.clipboard.writeText(
      evt.target.dataset.withdrawAccountDetailsValue
    )
    evt.target.classList.add(`--copied`)
    window.setTimeout(() => evt.target.classList.remove(`--copied`), 2000)
  }
}

Stimulus.register(`withdraw-account-details`, WithdrawAccountDetails)
