const inputRequiredDisable = (els) => {
  els.forEach((el) => {
    if (el.required) {
      el.__required = true
      el.required = false
      el.ariaRequired = false
    }
  })
}

const inputRequiredRestore = (els) => {
  els.forEach((el) => {
    if (el.__required === true) {
      el.required = true
      el.ariaRequired = true
    }
  })
}

export { inputRequiredDisable, inputRequiredRestore }
