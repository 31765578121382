import { Controller } from "@hotwired/stimulus"
import throttle from "raf-throttle"

import Stimulus from "../../shared/utils/stimulus"

// number

class TransfersPageNumber extends Controller {
  static values = { number: String }

  copy(e) {
    navigator.clipboard.writeText(this.numberValue)
    e.target.classList.add(`--copied`)
    window.setTimeout(() => e.target.classList.remove(`--copied`), 2000)
  }
}

Stimulus.register(`transfers-page-number`, TransfersPageNumber)

// table (order)

class TransfersPageOrder extends Controller {
  static targets = [`ref`, `refToggle`, `refCopy`]
  static values = { ref: String, refActive: Boolean }

  refActiveValueChanged() {
    if (this.hasRefTarget && this.hasRefToggleTarget) {
      this.refTarget.classList.toggle(`--active`, this.refActiveValue)
      this.refToggleTarget.classList.toggle(`--active`, this.refActiveValue)
    }
  }

  toggleRef = () => (this.refActiveValue = !this.refActiveValue)

  copyRef = () => {
    navigator.clipboard.writeText(this.refValue)
    this.refCopyTarget.classList.add(`--copied`)
    window.setTimeout(
      () => this.refCopyTarget.classList.remove(`--copied`),
      2000
    )
  }
}

Stimulus.register(`transfers-page-order`, TransfersPageOrder)

// table

class TransfersPageOrderDescription extends Controller {
  static targets = [`text`, `button`]
  static values = { active: Boolean }
  #resizeHandler = null

  connect() {
    this.#resizeHandler = throttle(this.toggleButtonVisibility)
    window.addEventListener(`resize`, this.#resizeHandler)
    this.toggleButtonVisibility()
  }

  disconnect() {
    window.removeEventListener(`resize`, this.#resizeHandler)
  }

  toggle = () => (this.activeValue = !this.activeValue)

  toggleButtonVisibility = () => {
    this.buttonTarget.classList.toggle(
      `hidden`,
      this.textTarget.offsetWidth >= this.textTarget.scrollWidth
    )
  }
}

Stimulus.register(
  `transfers-page-order-description`,
  TransfersPageOrderDescription
)

// deposit tabs + button visibility

document.addEventListener(`tabbedContent:tabSwitched`, (e) => {
  const { element, activeIndex } = e.detail
  if (element.classList.contains(`transfers-page-deposit-tabs`)) {
    const cardEl = element.closest(`.common-card`)
    if (cardEl) {
      cardEl.dispatchEvent(
        new CustomEvent(
          activeIndex === 0 ? `commonCard:hideFooter` : `commonCard:showFooter`
        )
      )
    }
  }
})
