import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie"

import application from "../utils/stimulus"

class PrivateConfirmation extends Controller {
  static values = { confirmationId: String }

  close() {
    Cookies.set(`private-confirmation`, this.confirmationIdValue, {
      sameSite: `Lax`,
    })
    this.element.remove()
  }
}

application.register(`private-confirmation`, PrivateConfirmation)
