import { Controller } from "@hotwired/stimulus"
import StickySidebar from "sticky-sidebar"

import Stimulus from "../../shared/utils/stimulus"

// savings

class HomePageSavings extends Controller {
  static targets = [`pie`, `chart`]

  connect() {
    new StickySidebar(this.pieTarget, { topSpacing: 20 })

    document.documentElement.addEventListener(
      `turbo:frame-render`,
      this.turboFrameRender
    )
  }

  turboFrameRender = (e) => {
    if (
      !e.target.classList.contains(`js--home-page-savings-account-card-frame`)
    )
      return

    this.chartTarget.dispatchEvent(
      new CustomEvent(`accountPieChart:setChartLabel`, {
        detail: {
          id: e.target.dataset.accountId,
          label: e.target.querySelector(`.account-card`).dataset.title,
        },
      })
    )
  }
}

Stimulus.register(`home-page-savings`, HomePageSavings)
