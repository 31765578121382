import { Controller } from "@hotwired/stimulus"
import { debounce } from "lodash"
import { nanoid } from "nanoid"
import { addClass, toggleClass } from "@oddcamp/js-utils/src/attribute"
import { getParents } from "@oddcamp/js-utils/src/selector"

import Stimulus from "../../shared/utils/stimulus"
import {
  inputRequiredDisable,
  inputRequiredRestore,
} from "../../shared/utils/input-required"

// citizenships

class KycPageCitizenships extends Controller {
  static targets = [
    `nationalitiesInput`,
    `submitButton`,
    `citizenshipCard`,
    `swedishPersonInput`,
    `usPerson`,
    `usPersonInput`,
    `nationality1`,
    `nationality1Input`,
    `multipleNationalities`,
    `multipleNationalitiesInput`,
    `nationality2`,
    `nationality2Input`,
    `taxation`,
    `taxations`,
    `swedishTaxationInput`,
    `taxationCountries`,
    `taxationCountriesAdd`,
    `taxationCountriesAddButton`,
    `taxationItem`,
    `taxationCountryLabel`,
    `taxationCountryInput`,
    `taxationCountryTinInput`,
    `taxationCountryRemove`,
  ]

  static values = {
    swedishPerson: String,
    usPerson: String,
    nationality1: String,
    multipleNationalities: String,
    nationality2: String,
    swedishTaxation: String,
  }

  #isValueResetNeeded = false
  #setTaxationOnInsertUs = false

  connect() {
    this.taxationsTarget.addEventListener(
      `cocoon:after-insert`,
      this.taxationInserted
    )

    setTimeout(() => {
      this.#isValueResetNeeded = true
      this.validateTinCodes()
    }, 0)
  }

  // changers

  swedishPersonValueChanged() {
    if (this.#isValueResetNeeded) {
      this.usPersonValue = ``
      this.usPersonInputTargets.forEach((t) => (t.checked = false))
    }

    this.usPersonTarget.classList.toggle(`hidden`, !this.swedishPersonValue)
  }

  usPersonValueChanged() {
    this.nationality1Value = nanoid()

    const showNationality1 =
      this.swedishPersonValue == `false` && this.usPersonValue == `false`

    this.nationality1Target.classList.toggle(`hidden`, !showNationality1)

    if (this.#isValueResetNeeded) {
      this.nationality1InputTarget.value = ``
      if (this.nationality1InputTarget.__CHOICES)
        this.nationality1InputTarget.__CHOICES.setChoiceByValue(``)

      this.nationalitiesInputTargets.forEach((t) => (t.value = ``))
      if (this.swedishPersonValue == `true`)
        this.nationalitiesInputTargets[0].value = `SE`
      if (this.usPersonValue == `true`)
        this.nationalitiesInputTargets[this.swedishPersonValue == `true` ? 1 : 0].value = `US` // eslint-disable-line prettier/prettier
    }
  }

  nationality1ValueChanged() {
    const showMultipleNationalities =
      this.swedishPersonValue &&
      this.usPersonValue &&
      (this.swedishPersonValue == `false` || this.usPersonValue == `false`)

    this.multipleNationalitiesTarget.classList.toggle(
      `hidden`,
      !showMultipleNationalities
    )

    if (this.#isValueResetNeeded) {
      this.multipleNationalitiesValue =
        this.swedishPersonValue == `true` && this.usPersonValue == `true`
          ? `false`
          : ``
      this.multipleNationalitiesInputTargets.forEach((t) => (t.checked = false))

      if (this.nationality2InputTarget.__CHOICES) {
        const options = [...this.nationality2InputTarget.__CHOICES_OPTIONS]
        options.forEach(
          (o) => (o.disabled = o.value && o.value == this.nationality1Value)
        )
        this.nationality2InputTarget.__CHOICES.setChoices(
          options,
          `value`,
          `label`,
          true
        )
      }

      if (
        this.nationality1Value.length == 2 &&
        this.swedishPersonValue &&
        this.usPersonValue &&
        this.swedishPersonValue == `false` &&
        this.usPersonValue == `false`
      )
        this.nationalitiesInputTargets[0].value = this.nationality1Value
    }
  }

  multipleNationalitiesValueChanged(value) {
    this.toggleCitizenshipCardFooterVisibility(true)

    if (this.#isValueResetNeeded) {
      this.swedishTaxationValue = ``
      this.swedishTaxationInputTargets.forEach((t) => (t.checked = false))
      this.nationality2Value = ``
      this.nationality2InputTarget.value = ``
      if (this.nationality2InputTarget.__CHOICES)
        this.nationality2InputTarget.__CHOICES.setChoiceByValue(``)
    }

    if (value) {
      this.nationality2Target.classList.toggle(`hidden`, value != `true`)
      this.toggleCitizenshipCardFooterVisibility(value == `true`)
    } else {
      this.nationality2Target.classList.add(`hidden`)
    }
  }

  nationality2ValueChanged(value) {
    if (this.#isValueResetNeeded) {
      this.swedishTaxationValue = ``
      this.swedishTaxationInputTargets.forEach((t) => (t.checked = false))

      if (this.swedishPersonValue == `false` || this.usPersonValue == `false`)
        this.nationalitiesInputTargets[1].value = value
    }

    if (value) {
      this.toggleCitizenshipCardFooterVisibility(!value)
    } else if (!this.multipleNationalitiesValue) {
      this.toggleCitizenshipCardFooterVisibility(true)
    }
  }

  swedishTaxationValueChanged(value) {
    if (this.#isValueResetNeeded) {
      this.taxationItemTargets.slice(1).forEach((t) => t.remove())
      this.taxationCountryInputTarget.value = ``
      this.taxationCountryTinInputTarget.value = ``
      if (this.taxationCountryInputTarget.__CHOICES)
        this.taxationCountryInputTarget.__CHOICES.setChoiceByValue(``).enable()
      this.taxationCountryRemoveTarget.classList.add(`hidden`)
      this.taxationCountryRemoveTarget.classList.remove(`--hidden`)
      this.taxationCountriesTarget.classList.toggle(`hidden`, !value)
      this.taxationCountriesAddTarget.classList.remove(`hidden`)
      this.toggleButtonEnabled(value == `true`)
    }

    if (value == `true`) {
      this.taxationCountryRemoveTarget.classList.add(`--hidden`)

      if (this.#isValueResetNeeded)
        this.taxationCountryInputTarget.__CHOICES
          .setChoiceByValue(`SE`)
          .disable()
    }

    if (this.usPersonValue == `true`) {
      if (value == `true`) {
        this.#setTaxationOnInsertUs = true

        if (this.#isValueResetNeeded) {
          this.taxationCountriesAddButtonTarget.click()
        }
      } else {
        this.taxationCountryRemoveTarget.classList.add(`--hidden`)

        if (this.#isValueResetNeeded)
          this.taxationCountryInputTarget.__CHOICES
            .setChoiceByValue(`US`)
            .disable()
      }
    }

    if (this.#isValueResetNeeded) {
      this.taxationCountryInputTarget.dispatchEvent(new CustomEvent(`change`))
      // this.setNationalitiesInputValue()
    }
  }

  // actions

  setSwedishPerson() {
    this.swedishPersonValue = (
      this.swedishPersonInputTargets.find((t) => t.checked) || { value: `` }
    ).value
  }

  setUsPerson() {
    this.usPersonValue = (
      this.usPersonInputTargets.find((t) => t.checked) || { value: `` }
    ).value
  }

  setNationality1() {
    this.nationality1Value = this.nationality1InputTarget.value
  }

  setMultipleNationalities() {
    this.multipleNationalitiesValue = (
      this.multipleNationalitiesInputTargets.find((t) => t.checked) || {
        value: ``,
      }
    ).value
  }

  setNationality2() {
    this.nationality2Value = this.nationality2InputTarget.value
  }

  setSwedishTaxation() {
    this.swedishTaxationValue = (
      this.swedishTaxationInputTargets.find((t) => t.checked) || { value: `` }
    ).value
  }

  trackTaxationInputChange = debounce(() => {
    let valid = true

    this.taxationCountryInputTargets.forEach((t) => {
      if (!t.value) valid = false
    })

    if (valid) {
      valid = this.validateTinCodes()
    }

    this.toggleCitizenshipTaxationCountryLabels()
    this.toggleButtonEnabled(valid)
  }, 500)

  beforeSubmit() {
    this.taxationCountryInputTargets.map((t) => t.__CHOICES.enable())
  }

  // event handlers

  taxationInserted = () => {
    if (this.#setTaxationOnInsertUs) {
      this.#setTaxationOnInsertUs = false
      this.taxationCountryRemoveTargets[1].classList.add(`--hidden`)
      this.taxationCountryInputTargets[1].value = `US`

      window.setTimeout(() => {
        this.taxationCountryInputTargets[1].__CHOICES
          .setChoiceByValue(`US`)
          .disable()
      }, 50)
    }
  }

  // helpers

  toggleButtonEnabled = (enabled) => {
    this.submitButtonTargets.forEach((t) => (t.disabled = !enabled))
  }

  toggleCitizenshipCardFooterVisibility = (on) => {
    this.citizenshipCardTarget.dispatchEvent(
      new CustomEvent(on ? `commonCard:showFooter` : `commonCard:hideFooter`)
    )
  }

  validateTinCodes = () => {
    return this.taxationCountryTinInputTargets.reduce((allFieldsValid, t) => {
      let valid = true

      if (t.required && !t.value) valid = false

      // Get parent container for this field
      const parent = getParents(t, `.kyc-page-taxation`)
      let selectedCountry = false

      // Grab what country is selected for this block
      if (parent[0])
        selectedCountry = parent[0].dataset.kycPageTaxationSelectedCountryValue

      // For specific countries, add a regex pattern to the input
      if (selectedCountry) {
        switch (selectedCountry) {
          case `US`:
            t.pattern = `(\\d{3})(-|)(\\d{2})(-|)(\\d{4})` // eslint-disable-line
            t.placeholder = `XXX-XX-XXXX`
            t.title = `Felaktigt format. Exempel: XXX-XX-XXXX`
            break
          case `DK`:
            t.pattern = `(\\d{6})-(\\d{4})` // eslint-disable-line
            t.placeholder = `XXXXXX-XXXX`
            t.title = `Felaktigt format. Exempel: XXXXXX-XXXX`
            break
          case `NO`:
            t.pattern = `(\\d{11})` // eslint-disable-line
            t.placeholder = `XXXXXXXXXXX`
            t.title = `Felaktigt format. Exempel: XXXXXXXXXXX`
            break
          case `FI`:
            t.pattern = `(\\d{6})(\\+|-|A)(\\d{3})([A-Z0-9]{1})` // eslint-disable-line
            t.placeholder = `XXXXXXAXXXE`
            t.title = `Felaktigt format. Exempel: XXXXXXAXXXE`
            break
          case `IS`:
            t.pattern = `(\\d{6})-(\\d{4})` // eslint-disable-line
            t.placeholder = `XXXXXX-XXXX`
            t.title = `Felaktigt format. Exempel: XXXXXX-XXXX`
            break
        }
      }

      return allFieldsValid && valid
    }, true)
  }

  // setNationalitiesInputValue = () => {
  //   this.nationalitiesInputTargets[0].value =
  //     this.swedishPersonValue == `true` ? `SE` : this.nationality1Value

  //   this.nationalitiesInputTargets[1].value =
  //     this.usPersonValue == `true` ? `US` : this.nationality2Value
  // }

  toggleCitizenshipTaxationCountryLabels = () => {
    this.taxationCountryLabelTargets.forEach((t, i) =>
      t.classList.toggle(`--primary`, i == 0)
    )
  }
}

Stimulus.register(`kyc-page-citizenships`, KycPageCitizenships)

// taxations

class KycPageTaxations extends Controller {
  static targets = [`add`, `item`, `itemRemove`, `country`]

  connect() {
    this.element.addEventListener(`cocoon:after-insert`, this.setThings)
    this.element.addEventListener(`cocoon:after-remove`, this.setThings)
    this.setThings()
  }

  setThings = () => {
    this.addTarget.classList.toggle(`hidden`, this.itemTargets.length >= 3)

    this.itemRemoveTarget.classList.toggle(
      `hidden`,
      this.itemTargets.length == 1
    )

    window.setTimeout(this.normalizeCountryOptions, 50)
  }

  normalizeCountryOptions = () => {
    const disabled = []

    this.countryTargets.forEach(
      (country) =>
        country.__CHOICES && disabled.push(country.__CHOICES.getValue(true))
    )

    this.countryTargets.forEach((country) => {
      if (country.__CHOICES) {
        const options = [...country.__CHOICES_OPTIONS]

        options.forEach((option) => {
          const value = country.__CHOICES.getValue(true)
          option.selected = option.value == value
          option.disabled = !option.selected && disabled.includes(option.value)
        })

        country.__CHOICES.setChoices(options, `value`, `label`, true)
      }
    })
  }

  countrySelected = () => {
    this.normalizeCountryOptions()
  }
}

Stimulus.register(`kyc-page-taxations`, KycPageTaxations)

// taxation

class KycPageTaxation extends Controller {
  static targets = [`tin`, `country`]
  static values = {
    tinCountries: Array,
    noTinCountries: Array,
    selectedCountry: String,
  }

  connect() {
    this.countrySelected()
  }

  countrySelected = () => {
    this.selectedCountryValue = this.countryTarget.value
  }

  selectedCountryValueChanged = () => {
    this.tinTarget.dispatchEvent(
      new CustomEvent(`setRequired`, {
        detail: {
          required: this.tinCountriesValue.includes(this.selectedCountryValue),
        },
      })
    )

    this.tinTarget.classList.toggle(
      `hidden`,
      this.noTinCountriesValue.includes(this.selectedCountryValue)
    )
  }
}

Stimulus.register(`kyc-page-taxation`, KycPageTaxation)

// autogiro

class KycPageAutogiro extends Controller {
  static values = { selected: String }
  static targets = [`group`]

  connect() {
    this.toggleGroups()
  }

  selectedValueChanged = () => {
    this.toggleGroups()
  }

  setSelected = (e) => {
    this.selectedValue = e.target.value
  }

  toggleGroups = () => {
    addClass(this.groupTargets, `hidden`)
    this.groupTargets.forEach((g) =>
      inputRequiredDisable(g.querySelectorAll(`input, select, textarea`))
    )

    if (this.selectedValue) {
      const activeGroupEl = this.groupTargets.find(
        (g) => g.dataset.value == this.selectedValue
      )

      if (activeGroupEl) {
        activeGroupEl.classList.remove(`hidden`)
        inputRequiredRestore(
          activeGroupEl.querySelectorAll(`input, select, textarea`)
        )
      }
    }
  }
}

Stimulus.register(`kyc-page-autogiro`, KycPageAutogiro)

// savings

class KycPageSavings extends Controller {
  static targets = [
    `occupation`,
    `occupationSpecification`,
    `requiredField`,
    `employmentField`,
    `employer`,
    `fundsOwnerInput`,
    `fundsOwnerInputField`,
    `fundsOwnerDependant`,
    `submitButton`,
  ]

  static values = { fundsOwnerError: String }

  connect() {
    window.setTimeout(this.occupationChange, 50)
    window.setTimeout(this.fundsOwnerChange, 50)
  }

  occupationChange = () => {
    const isOther = this.occupationTarget.value == `OTHER`
    const isEmployee = [
      `EMPLOYEE`,
      `EXECUTIVE_EMPLOYEE`,
      `SELF_EMPLOYED`,
    ].includes(this.occupationTarget.value)

    this.occupationSpecificationTarget.classList.toggle(`hidden`, !isOther)
    this.occupationSpecificationTarget.dispatchEvent(
      new CustomEvent(`setRequired`, {
        detail: { required: isOther },
      })
    )

    this.requiredFieldTargets.forEach((f) => {
      f.dispatchEvent(
        new CustomEvent(`setRequired`, {
          detail: { required: isEmployee },
        })
      )
    })

    toggleClass(this.employmentFieldTargets, `hidden`, !isEmployee)

    if (this.occupationTarget.value === `SELF_EMPLOYED`) {
      toggleClass(this.employerTarget, `hidden`, true)
    }
  }

  fundsOwnerChange = () => {
    const checkedEl = this.fundsOwnerInputTargets.find((i) => i.checked)
    const isNotOwner = checkedEl && checkedEl.value == `false` ? true : false

    toggleClass(this.fundsOwnerDependantTargets, `hidden`, isNotOwner)
    this.submitButtonTarget.disabled = isNotOwner

    this.fundsOwnerInputFieldTarget.dispatchEvent(
      new CustomEvent(`setError`, {
        detail: { message: isNotOwner ? this.fundsOwnerErrorValue : `` },
      })
    )
  }
}

Stimulus.register(`kyc-page-savings`, KycPageSavings)

// status

class KycPageStatus extends Controller {
  static values = { url: String }
  #timeout

  connect() {
    this.check()
  }

  disconnect() {
    window.clearTimeout(this.#timeout)
  }

  check = () => {
    this.#timeout = window.setTimeout(this.callApi, 2000)
  }

  callApi = () => {
    window.Rails.ajax({
      url: this.urlValue,
      type: `get`,
      success: (response) => {
        if (response.path) {
          window.location.href = response.path
        } else {
          this.check()
        }
      },
    })
  }
}

Stimulus.register(`kyc-page-status`, KycPageStatus)
