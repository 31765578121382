import { Controller } from "@hotwired/stimulus"
import { addClass, removeClass } from "@oddcamp/js-utils/src/attribute"

import Stimulus from "../utils/stimulus"

class ClickReveal extends Controller {
  static targets = [`trigger`, `body`]
  open() {
    addClass(this.triggerTarget, `hidden`)
    removeClass(this.bodyTarget, `hidden`)
  }

  close() {
    addClass(this.bodyTarget, `hidden`)
    removeClass(this.triggerTarget, `hidden`)
  }
}

Stimulus.register(`click-reveal`, ClickReveal)
