import { Controller } from "@hotwired/stimulus"
import throttle from "raf-throttle"

import Stimulus from "../utils/stimulus"

class ConfirmModal extends Controller {
  static targets = [`box`, `cancel`, `confirm`]
  static values = { show: Boolean }

  #winResizeHandler = null
  #alignmentEl =
    document.querySelector(`.auth-page-layout__main`) ||
    document.querySelector(`.layout-users__main`)

  connect() {
    this.element.__MODAL__.on(`show`, () => {
      if (this.hasCancelTarget) this.cancelTarget.focus()
      else if (this.hasConfirmTarget) this.confirmTarget.focus()
    })

    if (this.#alignmentEl) {
      this.element.__MODAL__.on(`show`, this.align)
      this.#winResizeHandler = throttle(this.align)
      window.addEventListener(`resize`, this.#winResizeHandler)
    }

    if (this.showValue) this.element.__MODAL__.show()
  }

  disconnect() {
    window.addEventListener(`resize`, this.#winResizeHandler)
  }

  cancel = () => {
    this.element.__MODAL__.hide()
  }

  align = () => {
    if (!this.element.__MODAL__.shown) return

    const { x, width } = this.#alignmentEl.getBoundingClientRect()
    const pos = x ? x / 2 : 0

    this.boxTarget.style.maxWidth = null
    const maxWidth = Math.min(
      parseFloat(
        window.getComputedStyle(this.boxTarget).getPropertyValue(`max-width`)
      ),
      width
    )
    this.boxTarget.style.maxWidth = `${maxWidth}px`
    this.boxTarget.style.transform = `translateX(${pos}px)`
  }
}

Stimulus.register(`confirm-modal`, ConfirmModal)
