import { Controller } from "@hotwired/stimulus"

import Stimulus from "../../shared/utils/stimulus"

class CommonCard extends Controller {
  static targets = [`footer`]

  connect() {
    if (this.hasFooterTarget) {
      this.element.addEventListener(`commonCard:showFooter`, this.showFooter)
      this.element.addEventListener(`commonCard:hideFooter`, this.hideFooter)
    }
  }

  disconnect() {
    if (this.hasFooterTarget) {
      this.element.removeEventListener(`commonCard:showFooter`, this.showFooter)
      this.element.removeEventListener(`commonCard:hideFooter`, this.hideFooter)
    }
  }

  showFooter = () => {
    this.footerTarget.classList.remove(`hidden`)
  }

  hideFooter = () => {
    this.footerTarget.classList.add(`hidden`)
  }
}

Stimulus.register(`common-card`, CommonCard)
