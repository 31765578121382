import { Controller } from "@hotwired/stimulus"

import Stimulus from "../utils/stimulus"

class FormField extends Controller {
  static targets = [`input`, `label`, `note`, `error`]

  connect() {
    this.element.addEventListener(`setRequired`, this.setRequired)

    if (this.hasInputTarget)
      this.inputTarget.addEventListener(`setValid`, this.toggleValid)

    if (this.hasErrorTarget) {
      this.element.addEventListener(`setError`, this.setError)

      if (this.hasInputTarget)
        this.inputTarget.addEventListener(`setError`, this.setError)
    }

    if (this.hasNoteTarget) {
      this.element.addEventListener(`setNote`, this.setError)

      if (this.hasInputTarget)
        this.inputTarget.addEventListener(`setNote`, this.setNote)
    }
  }

  setRequired = (e) => {
    const { required } = e.detail

    if (this.hasInputTarget) {
      this.inputTargets.forEach((i) => {
        i.required = required
        i.ariaRequired = required
      })
    }

    if (this.hasLabelTarget) {
      const labelEl = this.labelTarget.querySelector(`label`)

      if (labelEl) {
        const requiredEl = labelEl.querySelector(`abbr`)

        if (required) {
          if (requiredEl) {
            requiredEl.classList.remove(`hidden`)
          } else {
            labelEl.insertAdjacentHTML(
              `beforeend`,
              ` ${labelEl.dataset.requiredHtml}`
            )
          }
        } else {
          if (requiredEl) {
            requiredEl.classList.add(`hidden`)
          }
        }
      }
    }
  }

  setError = (e) => {
    const { message } = e.detail
    const isError = !!message
    this.element.classList.toggle(`form-field--error`, isError)
    this.errorTarget.innerHTML = message

    if (this.hasInputTarget) {
      this.inputTargets.forEach((i) => i.setAttribute(`aria-invalid`, isError))
    }
  }

  setNote = (e) => {
    const { message } = e.detail
    this.element.classList.toggle(`form-field--note`, !!message)
    this.noteTarget.innerHTML = message
  }

  toggleValid = (e) => {
    this.element.classList.toggle(`form-field--valid`, e.detail.valid)
  }
}

Stimulus.register(`form-field`, FormField)
