import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js"

import Stimulus from "../utils/stimulus"

class FormHint extends Controller {
  static values = { placeholder: String }
  #tippy = null

  connect() {
    this.#tippy = tippy(this.element, {
      content: this.element.innerHTML,
      allowHTML: true,
      interactive: true,
      interactiveBorder: 30,
    })

    this.element.innerHTML = this.placeholderValue
  }

  disconnect() {
    this.#tippy.destroy()
  }
}

Stimulus.register(`form-hint`, FormHint)
