import { Controller } from "@hotwired/stimulus"
import throttle from "raf-throttle"

import Stimulus from "../../shared/utils/stimulus"

class AccountRenamer extends Controller {
  static targets = [`box`, `input`, `button`]
  static values = { endpoint: String }

  #winResizeHandler = null
  #alignmentEl =
    document.querySelector(`.auth-page-layout__main`) ||
    document.querySelector(`.layout-users__main`)

  connect() {
    this.element.__MODAL__.on(`show`, () => {
      this.inputTarget.value = ``
      this.inputTarget.focus()
      this.buttonController.toggleLoadingDisabled(false)
    })

    if (this.#alignmentEl) {
      this.element.__MODAL__.on(`show`, this.align)
      this.#winResizeHandler = throttle(this.align)
      window.addEventListener(`resize`, this.#winResizeHandler)
    }
  }

  disconnect() {
    window.addEventListener(`resize`, this.#winResizeHandler)
  }

  cancel() {
    this.element.__MODAL__.hide()
  }

  submit = (e) => {
    e.preventDefault()
    this.buttonController.toggleLoadingDisabled(true)

    const id = this.element.__MODAL_TRIGGER__.dataset.accountRenamerId
    const name = this.inputTarget.value
    const url = window.decodeURI(this.endpointValue).replaceAll(`{{ID}}`, id)

    window.Rails.ajax({
      url: url,
      type: `post`,
      data: new URLSearchParams({
        nickname: name,
      }).toString(),
      complete: () => {
        this.cancel()
        if (this.accountCardController) this.accountCardController.rename(name)
      },
    })
  }

  align = () => {
    if (!this.element.__MODAL__.shown) return

    const { x, width } = this.#alignmentEl.getBoundingClientRect()
    const pos = x ? x / 2 : 0

    this.boxTarget.style.maxWidth = null
    const maxWidth = Math.min(
      parseFloat(
        window.getComputedStyle(this.boxTarget).getPropertyValue(`max-width`)
      ),
      width
    )
    this.boxTarget.style.maxWidth = `${maxWidth}px`
    this.boxTarget.style.transform = `translateX(${pos}px)`
  }

  get buttonController() {
    return this.application.getControllerForElementAndIdentifier(
      this.buttonTarget,
      `button`
    )
  }

  get accountCardController() {
    const element = this.element.__MODAL_TRIGGER__.closest(
      `[data-controller*="account-card"]`
    )
    if (!element) return null

    return this.application.getControllerForElementAndIdentifier(
      element,
      `account-card`
    )
  }
}

Stimulus.register(`account-renamer`, AccountRenamer)
