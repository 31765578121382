import { Controller } from "@hotwired/stimulus"
import { hasClosest } from "@oddcamp/js-utils/src/selector"

import Stimulus from "../../shared/utils/stimulus"

class WithdrawConfirmation extends Controller {
  static outlets = [`withdraw`]

  get buttonController() {
    return this.application.getControllerForElementAndIdentifier(
      this.buttonTarget,
      `button`
    )
  }

  confirm(evt) {
    evt.preventDefault()
    this.element.__MODAL__.hide()
    this.withdrawOutlet.submit()
  }
}

Stimulus.register(`withdraw-confirmation`, WithdrawConfirmation)
