import { Controller } from "@hotwired/stimulus"
import throttle from "raf-throttle"
import { delegateEventListener } from "@oddcamp/js-utils/src/event"

import Stimulus from "../../shared/utils/stimulus"

class DeleteConfirmation extends Controller {
  static targets = [`box`, `button`, `title`]
  static values = { endpoint: String }

  #winResizeHandler = null
  #alignmentEl =
    document.querySelector(`.auth-page-layout__main`) ||
    document.querySelector(`.layout-users__main`)

  #modalTrigger = document.getElementsByClassName(`dynamic-delete`)

  connect() {
    delegateEventListener(
      `[data-modal-show="${this.element.id.replace(/^modal-/, ``)}"]`,
      `click`,
      () => {
        this.titleTarget.innerHTML = this.#modalTrigger[0].dataset.deleteContent
      }
    )

    this.element.__MODAL__.on(`show`, () => {
      if (this.hasCancelTarget) this.cancelTarget.focus()
      else if (this.hasConfirmTarget) this.confirmTarget.focus()
    })

    if (this.#alignmentEl) {
      this.element.__MODAL__.on(`show`, this.align)
      this.#winResizeHandler = throttle(this.align)
      window.addEventListener(`resize`, this.#winResizeHandler)
    }

    if (this.showValue) this.element.__MODAL__.show()
  }

  disconnect() {
    window.addEventListener(`resize`, this.#winResizeHandler)
  }

  cancel() {
    this.element.__MODAL__.hide()
    this.buttonController.toggleLoadingDisabled(false)
  }

  submit = () => {
    this.buttonController.toggleLoadingDisabled(true)
    const id = this.element.__MODAL_TRIGGER__.dataset.deletePath

    window.Rails.ajax({
      url: id,
      type: `delete`,
      complete: () => {
        this.element.__MODAL_TRIGGER__.closest(`turbo-frame`).reload()

        window.setTimeout(() => {
          this.cancel()
        }, 2000)
      },
    })
  }

  align = () => {
    if (!this.element.__MODAL__.shown) return

    const { x, width } = this.#alignmentEl.getBoundingClientRect()
    const pos = x ? x / 2 : 0

    this.boxTarget.style.maxWidth = null
    const maxWidth = Math.min(
      parseFloat(
        window.getComputedStyle(this.boxTarget).getPropertyValue(`max-width`)
      ),
      width
    )
    this.boxTarget.style.maxWidth = `${maxWidth}px`
    this.boxTarget.style.transform = `translateX(${pos}px)`
  }

  get buttonController() {
    return this.application.getControllerForElementAndIdentifier(
      this.buttonTarget,
      `button`
    )
  }
}

Stimulus.register(`delete-confirmation`, DeleteConfirmation)
