import { Controller } from "@hotwired/stimulus"

import Stimulus from "../../shared/utils/stimulus"

class AccountCard extends Controller {
  static targets = [`title`]

  rename(name) {
    if (this.hasTitleTarget) this.titleTarget.innerHTML = name
  }
}

Stimulus.register(`account-card`, AccountCard)
